export enum SubscriptionPlanType {
    /**
     * Subscription is tied to an annual contract requiring that the subscriber maintain a number of routes at or above
     * the contracted minimum quantity.
     *
     * Payment is month to month, no prepayment required.
     */
    ANNUAL_CONTRACT_MINIMUM_ROUTES_REQUIRED_V1 = "ANNUAL_CONTRACT_MINIMUM_ROUTES_REQUIRED_V1",
    /**
     * Subscription is tied to an annual contract requiring the user to pre-pay for a set amount of routes
     */
    ANNUAL_CONTRACT_PREPAID_V1 = "ANNUAL_CONTRACT_PREPAID_V1",
    /**
     * Subscription is tied to an annual contract, requiring a mininum route commitment.
     */
    ANNUAL_NOT_PREPAID_ROUTE_MINIMUM_V1 = "ANNUAL_NOT_PREPAID_ROUTE_MINIMUM_V1",

    /**
     * Subscription is charged on month-to-month basis with no required lock in.
     */
    MONTH_TO_MONTH_V1 = "MONTH_TO_MONTH_V1",
    /**
     * Tiered subscription charged on month-to-month basis.
     */
    TIERED_MONTH_TO_MONTH_V1 = "TIERED_MONTH_TO_MONTH_V1",
}

export function mapSubscriptionPlanTypeToEnum(
    type: string
): SubscriptionPlanType {
    switch (type) {
        case SubscriptionPlanType.ANNUAL_CONTRACT_MINIMUM_ROUTES_REQUIRED_V1.valueOf():
            return SubscriptionPlanType.ANNUAL_CONTRACT_MINIMUM_ROUTES_REQUIRED_V1;
        case SubscriptionPlanType.ANNUAL_CONTRACT_PREPAID_V1.valueOf():
            return SubscriptionPlanType.ANNUAL_CONTRACT_PREPAID_V1;
        case SubscriptionPlanType.ANNUAL_NOT_PREPAID_ROUTE_MINIMUM_V1.valueOf():
            return SubscriptionPlanType.ANNUAL_NOT_PREPAID_ROUTE_MINIMUM_V1;
        case SubscriptionPlanType.MONTH_TO_MONTH_V1.valueOf():
            return SubscriptionPlanType.MONTH_TO_MONTH_V1;
        case SubscriptionPlanType.TIERED_MONTH_TO_MONTH_V1.valueOf():
            return SubscriptionPlanType.TIERED_MONTH_TO_MONTH_V1;
        default: {
            throw new Error(`Invalid Subscription plan type: ${type}`);
        }
    }
}

export function isAnnualContractPlanType(type: string) {
    return type !== SubscriptionPlanType.MONTH_TO_MONTH_V1.valueOf();
}