import React from "react";
import moment from "moment";
import { SubscriptionPlanType, SubscriptionPlanContract, ContractStatus } from '@models';
import { ColorPack } from '@reducers/colorMode';

const DEFAULT_MINIMUM_REQUIRED_ROUTES = 30;

export function getContractStatusText(contract: SubscriptionPlanContract, pricePerRoute: number, colorPack: ColorPack): {
  title: string;
  subTitle: string;
  contractTerms: string | undefined;
  secondaryText: string;
  color: string;
} {
  const {
    secondaryText,
    subTitle,
    color
  } = getContractSecondaryText(contract.status, contract.endDate, colorPack)

  return {
    title: 'ANNUAL CONTRACT',
    subTitle,
    contractTerms: getContractRouteTerms(contract.contractPlan, contract.minimumContractedRoutes ?? DEFAULT_MINIMUM_REQUIRED_ROUTES, contract.minimumSharedContractedRoutes, pricePerRoute),
    secondaryText,
    color,
  }
}

export function getContractRouteTerms (contractPlan: SubscriptionPlanType, minRequiredRoutes: number, minRequiredSharedRoutes: number | null, pricePerRoute: number) {
  const useSharedRoutesRequirement = typeof minRequiredSharedRoutes === 'number';
  const minRequiredRoutesToUse = useSharedRoutesRequirement ? minRequiredSharedRoutes : minRequiredRoutes;

  switch (contractPlan) {
    case SubscriptionPlanType.ANNUAL_CONTRACT_MINIMUM_ROUTES_REQUIRED_V1: {
      return `${minRequiredRoutesToUse} routes minimum x $${(pricePerRoute / 100).toFixed(0)}${useSharedRoutesRequirement ? ' (minimum shared across operations)' : ''}`;
    }

    case SubscriptionPlanType.ANNUAL_CONTRACT_PREPAID_V1: {
      return `$${(pricePerRoute / 100).toFixed(0)} per route`;
    }

    case SubscriptionPlanType.ANNUAL_NOT_PREPAID_ROUTE_MINIMUM_V1: {
      return `${minRequiredRoutesToUse} routes minimum x $${(pricePerRoute / 100).toFixed(0)}${useSharedRoutesRequirement ? ' (minimum shared across operations)' : ''}`;
    }

    case SubscriptionPlanType.MONTH_TO_MONTH_V1: {
      return ``
    }
  }
}

export function getContractSecondaryText(
  contractStatus: ContractStatus,
  contractEndDate: moment.Moment,
  colorPack: ColorPack,
): { subTitle: string; secondaryText: string; color: string } {
  switch (contractStatus) {
    case ContractStatus.ACTIVE: {
      return {
        subTitle: "",
        secondaryText: `Contract effective until ${contractEndDate.format(
          "MMMM Do YYYY"
        )}`,
        color: colorPack.green,
      };
    }

    case ContractStatus.EXPIRING: {
      const now = moment();
      const expiringInNDays = contractEndDate.diff(now, 'days');

      return {
        subTitle: "EXPIRING SOON",
        secondaryText: `Contract expiring in ${expiringInNDays} days`,
        color: colorPack.red,
      };
    }

    case ContractStatus.CANCELLING_BEFORE_CONTRACT_END: {
      return {
        subTitle: "CANCELLING",
        secondaryText: `Contract terminating before contract terms.`,
        color: colorPack.red,
      };
    }

    case ContractStatus.CANCELLED_BEFORE_CONTRACT_END: {
      return {
        subTitle: "CANCELLED",
        secondaryText: `Contract terminated before contract terms.`,
        color: colorPack.red,
      };
    }

    case ContractStatus.CANCELLED: {
      return {
        subTitle: "CANCELLED",
        secondaryText: `Contracted completed without renewal.`,
        color: colorPack.red,
      };
    }

    default: {
      return {
        subTitle: "",
        secondaryText: "",
        color: colorPack.green,
      };
    }
  }
}