import React, {useCallback, useState} from "react";
import moment from "moment";
import {Modal, View} from "react-native";
import {Button, InputText, PageSpinner, SectionTitle} from "../../Page";
import {InputSelect} from "../../common";
import {SubscriptionPlanType} from "@models";
import Calendar from "../../Calendar/Calendar";
import {useColorPack} from "@hooks/redux";
import "./ContractModal.css";

type Props = {
  close: () => void;
  submitContractChange: (
    type: SubscriptionPlanType,
    contractUri: string | null,
    contractSignedDate: string | null,
    minimumContractedRoutes: number | null,
    minimumSharedContractedRoutes: number | null,
  ) => Promise<void>;
  currentContractType: SubscriptionPlanType | null;
  currentContractUri: string | null;
  currentContractSignedDate: moment.Moment | null;
  subscribedRoutesCount: number;
  minimumContractedRoutes: number | null;
  minimumSharedContractedRoutes: number | null;
};
const selectionLabels = {
  [SubscriptionPlanType.MONTH_TO_MONTH_V1]: "No contract, monthly payments",
  [SubscriptionPlanType.ANNUAL_CONTRACT_MINIMUM_ROUTES_REQUIRED_V1]:
    "Annual contract, 30+ routes minimum, monthly payments",
  [SubscriptionPlanType.ANNUAL_CONTRACT_PREPAID_V1]:
    "Annual contract, prepaid, monthly payments for routes over prepayment",
  [SubscriptionPlanType.ANNUAL_NOT_PREPAID_ROUTE_MINIMUM_V1]:
    "Annual contract, contracted routes minimum, monthly payments",
  [SubscriptionPlanType.TIERED_MONTH_TO_MONTH_V1]: "Tiered subscription charged on month-to-month basis",
};

const options = [
  {
    key: SubscriptionPlanType.MONTH_TO_MONTH_V1,
    value: SubscriptionPlanType.MONTH_TO_MONTH_V1,
    label: "No contract, monthly payments",
  },
  {
    key: SubscriptionPlanType.ANNUAL_CONTRACT_MINIMUM_ROUTES_REQUIRED_V1,
    value: SubscriptionPlanType.ANNUAL_CONTRACT_MINIMUM_ROUTES_REQUIRED_V1,
    label: "Annual contract, 30+ routes minimum, monthly payments",
  },
];

export default function ContractModal(props: Props) {
  const colorPack = useColorPack();

  const {
    submitting,
    onSubmit,
    errorMessage,
    contractType,
    setContractType,
    contractUri,
    setContractUri,
    contractSignedDate,
    setContractSignedDate,
    validFormEntry,
    contractedMinimumRoutes,
    setContractedMinimumRoutes,
    contractedSharedMinimumRoutes,
    setContractedSharedMinimumRoutes,
  } = useContractChangeForm(props)

  return (
    <Modal visible onRequestClose={props.close} centerFullScreen>
      <View style={{ width: 500, backgroundColor: colorPack.base }}>
        <SectionTitle
          title="Subscription Contract"
          style={{ flexDirection: "column" }}
        >
          {submitting ? (
            <PageSpinner
              label="submitting"
              style={{ padding: 0, minHeight: 200 }}
            />
          ) : (
            <React.Fragment>
              <p className="error" style={{ margin: 5, color: colorPack.red }}>
                {errorMessage}
              </p>

              <div
                className="select-wrap"
                style={{
                  marginTop: 10,
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                <p className="label">Select new Contract Type</p>
                <InputSelect
                  isCommunity
                  value={contractType}
                  placeholder="Contract type"
                  onValueChange={(type: SubscriptionPlanType) =>
                    setContractType(type)
                  }
                  icon="file-sign"
                  label={"Contract Type"}
                  valueName={selectionLabels[contractType]}
                  inline
                  closeOnSelect
                  useValueForPicker
                  style={{
                    width: "100%",
                    marginLeft: 0,
                    marginRight: 0,
                    marginBottom: 20,
                  }}
                  wrapStyle={{ height: 50, flex: 1 }}
                  pickerStyle={{
                    color: colorPack.fullCont,
                  }}
                  options={options}
                />
              </div>

              {contractType !== SubscriptionPlanType.MONTH_TO_MONTH_V1 && (
                <InputText
                  label="Optional: Minimum Required Routes (per operation)"
                  placeholder={"Default: 30 routes"}
                  value={contractedMinimumRoutes ?? undefined}
                  required
                  valid={contractedMinimumRoutes === null || (typeof contractedMinimumRoutes === 'number' && !isNaN(contractedMinimumRoutes) && contractedMinimumRoutes > 0)}
                  invalidMessage={"Must be a numeric value larger than zero"}
                  onChange={({ target }) => {
                    const asNumber = parseInt(target.value);
                   
                    if (target.value === '') {
                      setContractedMinimumRoutes(null);
                    } else if (isNaN(asNumber)) {
                      setContractedMinimumRoutes(null)
                    } else {
                      setContractedMinimumRoutes(asNumber);
                    }
                  }}
                />
              )}

              {contractType !== SubscriptionPlanType.MONTH_TO_MONTH_V1 && (
                <InputText
                  label="Optional: Minimum Required Routes (shared)"
                  placeholder={"Default: 30 routes"}
                  value={contractedSharedMinimumRoutes ?? undefined}
                  required
                  valid={contractedSharedMinimumRoutes === null || (typeof contractedSharedMinimumRoutes === 'number' && !isNaN(contractedSharedMinimumRoutes) && contractedSharedMinimumRoutes > 0)}
                  invalidMessage={"Must be a numeric value larger than zero"}
                  onChange={({ target }) => {
                    const asNumber = parseInt(target.value);

                    if (target.value === '') {
                      setContractedSharedMinimumRoutes(null);
                    } else if (isNaN(asNumber)) {
                      setContractedSharedMinimumRoutes(null)
                    } else {
                      setContractedSharedMinimumRoutes(asNumber);
                    }
                  }}
                />
              )}

              {contractType !== SubscriptionPlanType.MONTH_TO_MONTH_V1 && (
                <InputText
                  label="Contract Link"
                  placeholder={"https://docusign.com/..."}
                  value={contractUri}
                  required
                  valid={isValidDocusignUri(contractUri)}
                  onChange={({ target }) => {
                    setContractUri(target.value);
                  }}
                />
              )}

              {contractType !== SubscriptionPlanType.MONTH_TO_MONTH_V1 && (
                <div
                  className="calendar-wrap"
                  style={{
                    marginTop: 10,
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                  }}
                >
                  <p className="label">
                    Date of Contract Signature
                    {!contractSignedDate && (
                      <span style={{ marginLeft: 10, color: colorPack.red }}>
                        Required
                      </span>
                    )}
                  </p>

                  <Calendar
                    initialDate={contractSignedDate}
                    onDateClick={(e, dateMoment) => {
                      setContractSignedDate(dateMoment);
                    }}
                    timeZone={"UTC"}
                    preventRangeSelection
                    style={{ borderColor: colorPack.border, borderWidth: 1 }}
                  />
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 15,
                }}
              >
                <Button
                  disabled={!validFormEntry}
                  onClick={onSubmit}
                  color={colorPack.green}
                >
                  Submit
                </Button>
              </div>
            </React.Fragment>
          )}
        </SectionTitle>
      </View>
    </Modal>
  );
}

function useContractChangeForm ({
  close,
  submitContractChange,
  currentContractType,
  currentContractUri,
  currentContractSignedDate,
  minimumContractedRoutes,
  minimumSharedContractedRoutes,
}: Props) {
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [contractType, setContractType] = useState(
    currentContractType || SubscriptionPlanType.MONTH_TO_MONTH_V1
  );
  const [contractedMinimumRoutes, setContractedMinimumRoutes] = useState<number | null>(minimumContractedRoutes ?? null);
  const [contractedSharedMinimumRoutes, setContractedSharedMinimumRoutes] = useState<number | null>(minimumSharedContractedRoutes ?? null);

  const [contractUri, setContractUri] = useState(currentContractUri || "");
  const [contractSignedDate, setContractSignedDate] = useState(
    currentContractSignedDate || moment()
  );

  const planTypeUnchanged = currentContractType === contractType;

  const validFormEntry =
    contractType === SubscriptionPlanType.MONTH_TO_MONTH_V1
      ? true
      : isValidDocusignUri(contractUri) && contractSignedDate;

  const onSubmit = async () => {
    try {
      setErrorMessage("");
      setSubmitting(true);

      if (planTypeUnchanged) {
        return setErrorMessage(
          "Cannot submit changes for existing subscription type"
        )
      }
      if (!validFormEntry) {
        return setErrorMessage(
          "Cannot submit, please fill out all required fields"
        );
      }

      await submitContractChange(contractType, contractUri, contractSignedDate.format("YYYY-MM-DD"), contractedMinimumRoutes, contractedSharedMinimumRoutes);

      close();
    } catch (err) {
      console.error(err);
      setErrorMessage("Contract change failed");
    } finally {
      setSubmitting(false);
    }
  };

  return {
    onSubmit,
    submitting,
    errorMessage,
    setContractType,
    contractType,
    setContractUri,
    contractUri,
    setContractSignedDate,
    contractSignedDate,
    validFormEntry,
    contractedMinimumRoutes,
    setContractedMinimumRoutes,
    contractedSharedMinimumRoutes,
    setContractedSharedMinimumRoutes
  }
}
function isValidDocusignUri(uri: string) {
  return typeof uri === 'string' && uri.length > 10;
}
